"use strict";

var _interopRequireDefault = require("/Users/jiangxingliang/Desktop/zsty-zs/web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("/Users/jiangxingliang/Desktop/zsty-zs/web/node_modules/core-js/modules/es6.array.iterator.js");
require("/Users/jiangxingliang/Desktop/zsty-zs/web/node_modules/core-js/modules/es6.promise.js");
require("/Users/jiangxingliang/Desktop/zsty-zs/web/node_modules/core-js/modules/es6.object.assign.js");
require("/Users/jiangxingliang/Desktop/zsty-zs/web/node_modules/core-js/modules/es7.promise.finally.js");
var _vue = _interopRequireDefault(require("vue"));
var _jsCookie = _interopRequireDefault(require("js-cookie"));
require("normalize.css/normalize.css");
var _elementUi = _interopRequireDefault(require("element-ui"));
var _mavonEditor = _interopRequireDefault(require("mavon-editor"));
require("mavon-editor/dist/css/index.css");
var _Dict = _interopRequireDefault(require("./components/Dict"));
var _permission = _interopRequireDefault(require("@/utils/permission"));
var _Permission = _interopRequireDefault(require("./components/Permission"));
require("./assets/styles/element-variables.scss");
require("./assets/styles/index.scss");
var _vueHighlightjs = _interopRequireDefault(require("vue-highlightjs"));
require("highlight.js/styles/atom-one-dark.css");
var _App = _interopRequireDefault(require("./App"));
var _store = _interopRequireDefault(require("./store"));
var _routers = _interopRequireDefault(require("./router/routers"));
require("./assets/icons");
require("./router/index");
require("echarts-gl");
var _element = require("./utils/element");
//

// 数据字典

// 权限指令

// global css

// 代码高亮

// icon
// permission control

//修改表头样式

_vue.default.prototype.$handerStyleEvent = _element.handerStyleEvent;
_vue.default.use(_permission.default);
_vue.default.use(_vueHighlightjs.default);
_vue.default.use(_mavonEditor.default);
_vue.default.use(_Permission.default);
_vue.default.use(_Dict.default);
_vue.default.use(_elementUi.default, {
  size: _jsCookie.default.get('size') || 'small' //               set element-ui default size
});

_vue.default.config.productionTip = false;
new _vue.default({
  el: '#app',
  router: _routers.default,
  store: _store.default,
  data: function data() {
    return {
      testDialog: false,
      //测试点位的正确性
      testDwbh: [],
      //测试的点位编号
      fullState: false,
      //全屏的状态
      alarmNum: 0,
      //报警的条数
      isNotice: false,
      //是否弹出报警弹框
      loading: false,
      //全局遮罩
      clickNum: 0 //判断是否是第一次启动
    };
  },

  render: function render(h) {
    return h(_App.default);
  }
});