"use strict";

var _interopRequireDefault = require("/Users/jiangxingliang/Desktop/zsty-zs/web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.download = download;
exports.getPromise = getPromise;
exports.initData = initData;
var _request = _interopRequireDefault(require("@/utils/request"));
var _qs = _interopRequireDefault(require("qs"));
function initData(url, params) {
  return (0, _request.default)({
    url: url + '?' + _qs.default.stringify(params, {
      indices: false
    }),
    method: 'get'
  });
}
function download(url, params) {
  return (0, _request.default)({
    url: url + '?' + _qs.default.stringify(params, {
      indices: false
    }),
    method: 'get',
    responseType: 'blob'
  });
}
function getPromise(url) {
  var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
    size: 2000
  };
  return new Promise(function (resolve) {
    initData(url, params).then(function (res) {
      if (res.content) {
        resolve(res.content);
      } else {
        resolve(res);
      }
    });
  });
}