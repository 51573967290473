"use strict";

var _interopRequireDefault = require("/Users/jiangxingliang/Desktop/zsty-zs/web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.constantRouterMap = void 0;
var _vue = _interopRequireDefault(require("vue"));
var _vueRouter = _interopRequireDefault(require("vue-router"));
var _index = _interopRequireDefault(require("../layout/index"));
_vue.default.use(_vueRouter.default);
var constantRouterMap = [{
  path: '/energyMge',
  meta: {
    title: '能耗管理系统',
    noCache: true
  },
  component: function component(resolve) {
    return require(['@/screens/energyMge'], resolve);
  },
  hidden: true
}, {
  path: '/loginTest',
  meta: {
    title: '登录测试',
    noCache: true
  },
  component: function component(resolve) {
    return require(['@/views/loginTest'], resolve);
  },
  hidden: true
}, {
  path: '/login',
  meta: {
    title: '登录',
    noCache: true
  },
  component: function component(resolve) {
    return require(['@/views/login'], resolve);
  },
  hidden: true
}, {
  path: '/404',
  component: function component(resolve) {
    return require(['@/views/features/404'], resolve);
  },
  hidden: true
}, {
  path: '/401',
  component: function component(resolve) {
    return require(['@/views/features/401'], resolve);
  },
  hidden: true
}, {
  path: '/redirect',
  component: _index.default,
  hidden: true,
  children: [{
    path: '/redirect/:path*',
    component: function component(resolve) {
      return require(['@/views/features/redirect'], resolve);
    }
  }]
}, {
  path: '/after',
  component: _index.default,
  redirect: '/dashboard',
  children: [{
    path: '/dashboard',
    component: function component(resolve) {
      return require(['@/views/home'], resolve);
    },
    name: 'Dashboard',
    meta: {
      title: '首页',
      icon: 'index',
      affix: true,
      noCache: true
    }
  }]
}, {
  path: '/',
  component: function component(resolve) {
    return require(['@/front/index'], resolve);
  },
  redirect: '/front'
}, {
  path: '/front',
  meta: {
    title: '大屏',
    noCache: true
  },
  component: function component(resolve) {
    return require(['@/front/index'], resolve);
  },
  hidden: true
}, {
  path: '/coldSource',
  meta: {
    title: '冷冻站',
    noCache: true
  },
  component: function component(resolve) {
    return require(['@/screens/coldSource'], resolve);
  },
  hidden: true
}, {
  path: '/ahu',
  meta: {
    title: '空调',
    noCache: true
  },
  component: function component(resolve) {
    return require(['@/screens/ahu'], resolve);
  },
  hidden: true
}, {
  path: '/adsorb',
  meta: {
    title: '测试吸附',
    noCache: true
  },
  component: function component(resolve) {
    return require(['@/screens/adsorb'], resolve);
  },
  hidden: true
}, {
  path: '/report',
  meta: {
    title: '报表',
    noCache: true
  },
  component: function component(resolve) {
    return require(['@/screens/report'], resolve);
  },
  hidden: true
}, {
  path: '/testLayout',
  meta: {
    title: '大屏布局',
    noCache: true
  },
  component: function component(resolve) {
    return require(['@/screens/testLayout'], resolve);
  },
  hidden: true
}, {
  path: '/LayoutStyle',
  meta: {
    title: '首页布局样式',
    noCache: true
  },
  component: function component(resolve) {
    return require(['@/components/LayoutStyle'], resolve);
  },
  hidden: true
}, {
  path: '/north',
  meta: {
    title: '北区面板',
    noCache: true
  },
  component: function component(resolve) {
    return require(['@/screens/north'], resolve);
  },
  hidden: true
}, {
  path: '/south',
  meta: {
    title: '南区面板',
    noCache: true
  },
  component: function component(resolve) {
    return require(['@/screens/south'], resolve);
  },
  hidden: true
}, {
  path: '/demo',
  meta: {
    title: 'demo',
    noCache: true
  },
  component: function component(resolve) {
    return require(['@/screens/demo'], resolve);
  },
  hidden: true
}, {
  path: '/tagTest',
  meta: {
    title: 'tagTest',
    noCache: true
  },
  component: function component(resolve) {
    return require(['@/screens/tagTest'], resolve);
  },
  hidden: true
}, {
  path: '/user',
  component: _index.default,
  hidden: true,
  redirect: 'noredirect',
  children: [{
    path: 'center',
    component: function component(resolve) {
      return require(['@/views/system/user/center'], resolve);
    },
    name: '个人中心',
    meta: {
      title: '个人中心'
    }
  }]
}];
exports.constantRouterMap = constantRouterMap;
var _default = new _vueRouter.default({
  // mode: 'hash',
  mode: 'history',
  scrollBehavior: function scrollBehavior() {
    return {
      y: 0
    };
  },
  routes: constantRouterMap
});
exports.default = _default;