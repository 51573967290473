"use strict";

var _interopRequireDefault = require("/Users/jiangxingliang/Desktop/zsty-zs/web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.beforeEdit = beforeEdit;
exports.default = void 0;
exports.fomatterVal = fomatterVal;
exports.fomatterWz = fomatterWz;
exports.handerStyleEvent = handerStyleEvent;
exports.uploadImage = uploadImage;
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _defineProperty2 = _interopRequireDefault(require("/Users/jiangxingliang/Desktop/zsty-zs/web/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function handerStyleEvent(row, column, rowIndex, columnIndex) {
  return {
    background: '#eee'
  };
}
/**
 * 
 * @param {*} form 
 * @param {*} il 最大照片数
 * @param {*} fl 最大档案数
 */
function uploadImage(form, il, fl, iList, fList, t, o) {
  var newForm = _objectSpread({}, form);
  var formData = new FormData();
  var pA = [],
    fA = [];
  for (var i = 1; i <= il; i++) {
    pA.push('picture' + i);
  }
  for (var j = 1; j <= fl; j++) {
    fA.push('file' + j);
  }
  var oldP = [],
    newP = [];
  iList.forEach(function (item) {
    if (!item.raw) {
      oldP.push(item.name);
    } else {
      newP.push(item.raw);
    }
  });
  var arr = pA.filter(function (ele) {
    return oldP.indexOf(ele) === -1;
  });
  for (var k = 0; k < arr.length; k++) {
    if (newP[k]) {
      formData.set(arr[k], newP[k]);
    } else {
      newForm[arr[k]] = '';
    }
  }
  var oldF = [],
    newF = [];
  fList.forEach(function (item) {
    if (!item.raw) {
      oldF.push(item.signal);
    } else {
      newF.push(item.raw);
    }
  });
  var arr1 = fA.filter(function (ele) {
    return oldF.indexOf(ele) === -1;
  });
  for (var _k = 0; _k < arr1.length; _k++) {
    if (newF[_k]) {
      formData.set(arr1[_k], newF[_k]);
    } else {
      newForm[arr1[_k]] = '';
    }
  }
  formData.append(t, JSON.stringify(newForm));
  return formData;
}

/**
 * 带有图片上传的组件编辑前操作
 * @param {*} form 
 * @returns 
 */
function beforeEdit(form, baseApi) {
  var p = ['picture1', 'picture2', 'picture3', 'picture4', 'picture5'];
  var f = ['file1', 'file2', 'file3', 'file4', 'file5'];
  var obj = {
    image: [],
    file: []
  };
  for (var i = 0; i < 5; i++) {
    if (form[p[i]]) {
      obj['image'].push({
        name: p[i],
        url: baseApi + '/deviceattach/' + form[p[i]].split('/')[3]
      });
    }
    if (form[f[i]]) {
      obj['file'].push({
        name: form[f[i]].split('/')[3],
        signal: f[i],
        url: baseApi + '/deviceattach/' + form[f[i]].split('/')[3]
      });
    }
  }
  return obj;
}

//格式化位置和部门
function fomatterWz(val, data) {
  var result;
  if (val == 0) {
    return '';
  }
  data.forEach(function (_ref) {
    var value = _ref.value,
      label = _ref.label,
      parent = _ref.parent;
    if (val == value) {
      result = label;
      if (parent != 0) {
        result = fomatterWz(parent, data) + '/' + result;
      }
    }
  });
  return result;
}

//格式化表格的值
function fomatterVal(row, data, tag) {
  var result = '';
  data.forEach(function (item) {
    if (row[tag] == item.value) {
      result = item.label;
    }
  });
  return result;
}
var _default = {
  handerStyleEvent: handerStyleEvent,
  uploadImage: uploadImage,
  fomatterWz: fomatterWz,
  fomatterVal: fomatterVal
};
exports.default = _default;