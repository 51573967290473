var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.$root.loading,
          expression: "$root.loading",
        },
      ],
      style: { backgroundColor: _vm.color },
      attrs: {
        id: "app",
        "element-loading-text": "拼命加载中",
        "element-loading-spinner": "el-icon-loading",
        "element-loading-background": "rgb(0, 0, 0)",
      },
    },
    [
      _c("router-view"),
      _vm._v(" "),
      _c("AlarmDig", {
        attrs: { showState: _vm.show, alarmContent: _vm.alarmContent },
        on: {
          changeState: function ($event) {
            _vm.show = false
          },
        },
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        { attrs: { visible: _vm.testShow }, on: { close: _vm.close } },
        [
          _c(
            "el-table",
            { attrs: { data: _vm.testData } },
            [
              _c("el-table-column", {
                attrs: { prop: "dwbh", label: "逻辑点位中的点位" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "dwbh1", label: "图纸中的点位" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "displayName", label: "对应元素节点" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "sbbh", label: "对应的设备" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "board", label: "对应看板" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.$root.testDialog = false
                },
              },
            },
            [_vm._v("确认")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }