"use strict";

var _interopRequireDefault = require("/Users/jiangxingliang/Desktop/zsty-zs/web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.add = add;
exports.default = void 0;
exports.del = del;
exports.edit = edit;
exports.edit1 = edit1;
exports.get = get;
exports.getdwbh = getdwbh;
exports.moddwbh = moddwbh;
var _request = _interopRequireDefault(require("@/utils/request"));
function get(param) {
  return (0, _request.default)({
    url: 'api/ufLdzdwb',
    method: 'get',
    param: param
  });
}
function getdwbh(dwbh) {
  return (0, _request.default)({
    url: 'api/ufLdzdwb/dwbh?dwbh=' + dwbh,
    method: 'get'
  });
}
function moddwbh(data) {
  return (0, _request.default)({
    url: 'api/ufLdzdwb',
    method: 'post',
    data: data
  });
}
function add(data) {
  return (0, _request.default)({
    url: 'api/ufLdzdwb',
    method: 'post',
    data: data
  });
}
function del(ids) {
  return (0, _request.default)({
    url: 'api/ufLdzdwb/',
    method: 'delete',
    data: ids
  });
}
function edit(data) {
  return (0, _request.default)({
    url: 'api/ufLdzdwb',
    method: 'put',
    data: data
  });
}
function edit1(data) {
  return (0, _request.default)({
    url: 'api/ufLdzdwb/updateDz',
    method: 'post',
    data: data
  });
}
var _default = {
  add: add,
  edit: edit,
  del: del,
  getdwbh: getdwbh,
  moddwbh: moddwbh,
  get: get,
  edit1: edit1
};
exports.default = _default;