"use strict";

var _interopRequireDefault = require("/Users/jiangxingliang/Desktop/zsty-zs/web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _elementVariables = _interopRequireDefault(require("@/assets/styles/element-variables.scss"));
var _settings = _interopRequireDefault(require("@/settings"));
var tagsView = _settings.default.tagsView,
  fixedHeader = _settings.default.fixedHeader,
  sidebarLogo = _settings.default.sidebarLogo,
  uniqueOpened = _settings.default.uniqueOpened,
  showFooter = _settings.default.showFooter,
  footerTxt = _settings.default.footerTxt,
  caseNumber = _settings.default.caseNumber;
var state = {
  theme: _elementVariables.default.theme,
  showSettings: false,
  tagsView: tagsView,
  fixedHeader: fixedHeader,
  sidebarLogo: sidebarLogo,
  uniqueOpened: uniqueOpened,
  showFooter: showFooter,
  footerTxt: footerTxt,
  caseNumber: caseNumber
};
var mutations = {
  CHANGE_SETTING: function CHANGE_SETTING(state, _ref) {
    var key = _ref.key,
      value = _ref.value;
    if (state.hasOwnProperty(key)) {
      state[key] = value;
    }
  }
};
var actions = {
  changeSetting: function changeSetting(_ref2, data) {
    var commit = _ref2.commit;
    commit('CHANGE_SETTING', data);
  }
};
var _default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};
exports.default = _default;