var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: { id: "hamburger-container", "is-active": _vm.sidebar.opened },
        on: { toggleClick: _vm.toggleSideBar },
      }),
      _vm._v(" "),
      _c("breadcrumb", {
        staticClass: "breadcrumb-container",
        attrs: { id: "breadcrumb-container" },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          _vm.device !== "mobile"
            ? [
                _c("search", {
                  staticClass: "right-menu-item",
                  attrs: { id: "header-search" },
                }),
                _vm._v(" "),
                _c("DigitalClock", {
                  staticStyle: {
                    float: "left",
                    "font-size": "18px",
                    "margin-right": "10px",
                  },
                }),
                _vm._v(" "),
                _c(
                  "el-tooltip",
                  {
                    attrs: {
                      content: "驾驶舱",
                      effect: "dark",
                      placement: "bottom",
                    },
                  },
                  [
                    _c(
                      "router-link",
                      {
                        staticStyle: { float: "left", "font-size": "18px" },
                        attrs: { to: { path: "/front" } },
                      },
                      [_vm._v("前台展示")]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-tooltip",
                  {
                    attrs: {
                      content: "项目文档",
                      effect: "dark",
                      placement: "bottom",
                    },
                  },
                  [_c("Doc", { staticClass: "right-menu-item hover-effect" })],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-tooltip",
                  {
                    attrs: {
                      content: "全屏缩放",
                      effect: "dark",
                      placement: "bottom",
                    },
                  },
                  [
                    _c("screenfull", {
                      staticClass: "right-menu-item hover-effect",
                      attrs: { id: "screenfull" },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-tooltip",
                  {
                    attrs: {
                      content: "布局设置",
                      effect: "dark",
                      placement: "bottom",
                    },
                  },
                  [
                    _c("size-select", {
                      staticClass: "right-menu-item hover-effect",
                      attrs: { id: "size-select" },
                    }),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-dropdown",
            {
              staticClass: "avatar-container right-menu-item hover-effect",
              attrs: { trigger: "click" },
            },
            [
              _c("div", { staticClass: "avatar-wrapper" }, [
                _c("img", {
                  staticClass: "user-avatar",
                  attrs: {
                    src: _vm.user.avatarName
                      ? _vm.baseApi + "/avatar/" + _vm.user.avatarName
                      : _vm.Avatar,
                  },
                }),
                _vm._v(" "),
                _c("i", { staticClass: "el-icon-caret-bottom" }),
              ]),
              _vm._v(" "),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [
                  _c(
                    "span",
                    {
                      staticStyle: { display: "block" },
                      on: {
                        click: function ($event) {
                          _vm.show = true
                        },
                      },
                    },
                    [
                      _c("el-dropdown-item", [
                        _vm._v("\n            布局设置\n          "),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "router-link",
                    { attrs: { to: "/user/center" } },
                    [
                      _c("el-dropdown-item", [
                        _vm._v("\n            个人中心\n          "),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticStyle: { display: "block" },
                      on: { click: _vm.open },
                    },
                    [
                      _c("el-dropdown-item", { attrs: { divided: "" } }, [
                        _vm._v("\n            退出登录\n          "),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }