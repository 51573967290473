"use strict";

var _interopRequireDefault = require("/Users/jiangxingliang/Desktop/zsty-zs/web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getToken = getToken;
exports.removeToken = removeToken;
exports.removeUserId = removeUserId;
exports.setToken = setToken;
exports.setUserId = setUserId;
var _jsCookie = _interopRequireDefault(require("js-cookie"));
var _settings = _interopRequireDefault(require("@/settings"));
var TokenKey = _settings.default.TokenKey;
function getToken() {
  return _jsCookie.default.get(TokenKey);
}
function setToken(token, rememberMe) {
  if (rememberMe) {
    return _jsCookie.default.set(TokenKey, token, {
      expires: _settings.default.tokenCookieExpires
    });
  } else return _jsCookie.default.set(TokenKey, token);
}
function setUserId(userId) {
  return _jsCookie.default.set('userId', userId, {
    expires: _settings.default.tokenCookieExpires
  });
}
function removeUserId() {
  return _jsCookie.default.remove('userId');
}
function removeToken() {
  return _jsCookie.default.remove(TokenKey);
}