var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: "报警信息",
        visible: _vm.dialogVisible,
        width: "45%",
        top: "40vh",
        modal: false,
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.closeDialog,
      },
    },
    [
      _c("div", { staticClass: "content" }, [
        _c("span", { staticClass: "errImg" }, [
          _c("img", {
            attrs: {
              src: require("../../assets/images/error.png"),
              alt: "",
              srcset: "",
            },
          }),
        ]),
        _vm._v(" "),
        _c("span", [_vm._v(_vm._s(_vm.alarmContent))]),
      ]),
      _vm._v(" "),
      _c("el-button", { on: { click: _vm.jumpToAlarm } }, [
        _vm._v("点击查看详情"),
      ]),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }