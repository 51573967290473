"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'CrayonClock',
  components: {},
  data: function data() {
    return {
      time: '',
      // date: "",
      // week: "",
      timerHelper: false
    };
  },
  mounted: function mounted() {
    this.timerHelper = setInterval(this.updateTime, 1000);
  },
  beforeDestroy: function beforeDestroy() {
    if (this.timerHelper) {
      clearInterval(this.timerHelper);
      this.timerHelper = false;
    }
  },
  methods: {
    zeroPadding: function zeroPadding(num, digit) {
      var zero = '';
      for (var i = 0; i < digit; i++) {
        zero += '0';
      }
      return (zero + num).slice(-digit);
    },
    updateTime: function updateTime() {
      var week = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'];
      var cd = new Date();
      this.time = this.zeroPadding(cd.getHours(), 2) + ':' + this.zeroPadding(cd.getMinutes(), 2) + ':' + this.zeroPadding(cd.getSeconds(), 2);
      // this.date =
      // this.zeroPadding(cd.getFullYear(), 4) +
      // "-" +
      // this.zeroPadding(cd.getMonth() + 1, 2) +
      // "-" +
      // this.zeroPadding(cd.getDate(), 2);

      // this.week = week[cd.getDay()];
    }
  }
};
exports.default = _default;