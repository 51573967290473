"use strict";

var _interopRequireDefault = require("/Users/jiangxingliang/Desktop/zsty-zs/web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _logo = _interopRequireDefault(require("@/assets/images/logo.png"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  data: function data() {
    return {
      title: '庄生Focus_Ctl平台',
      logo: _logo.default
    };
  },
  mounted: function mounted() {
    this.title = this.$store.state.webMessage.backgroundTitle;
    this.logo = this.$store.state.webMessage.backgroundIcon;
  }
};
exports.default = _default;