"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
// 适配 Nginx 反向代理
var baseUrl = process.env.VUE_APP_BASE_API === '/' ? '' : process.env.VUE_APP_BASE_API;
var api = {
  state: {
    //设备台账二维码
    deviceCodeApi: baseUrl + '/api/deviceLedger',
    //设备台账二维码
    deviceLogApi: baseUrl + '/api/deviceMaintainLog',
    // 部署包上传
    deployUploadApi: baseUrl + '/api/deploy/upload',
    // SQL脚本上传
    databaseUploadApi: baseUrl + '/api/database/upload',
    // 实时控制台
    socketApi: baseUrl + '/websocket?token=kl',
    // 图片上传
    imagesUploadApi: baseUrl + '/api/localStorage/pictures',
    // 修改头像
    updateAvatarApi: baseUrl + '/api/users/updateAvatar',
    // 上传文件到七牛云
    qiNiuUploadApi: baseUrl + '/api/qiNiuContent',
    // Sql 监控
    sqlApi: baseUrl + '/druid/index.html',
    // swagger
    swaggerApi: baseUrl + '/swagger-ui.html',
    // 文件上传
    fileUploadApi: baseUrl + '/api/localStorage',
    // 上传压缩文件
    versionFileAPI: baseUrl + 'api/localStorage/createVersionFile',
    // baseUrl，
    baseApi: baseUrl
  }
};
var _default = api;
exports.default = _default;