"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
var _data = require("@/api/data");
var state = {
  loginIcon: null,
  // 登录图标
  loginText: null,
  // 登录文本
  frontZhTitle: null,
  // 前台中文标题
  frontEnTitle: null,
  // 前台英文标题
  frontIcon: null,
  // 前台图标
  browserTitle: null,
  // 浏览器标题
  browserIcon: null,
  // 浏览器图标
  backgroundTitle: null,
  // 后台标题
  backgroundIcon: null // 后台图标
};

var mutations = {
  SET_UserMsg: function SET_UserMsg(state, data) {
    console.log(data);
    state.loginIcon = data.loginIcon;
    state.loginText = data.loginText;
    state.frontZhTitle = data.frontZhTitle;
    state.frontEnTitle = data.frontEnTitle;
    state.frontIcon = data.frontIcon;
    state.browserTitle = data.browserTitle;
    state.browserIcon = data.browserIcon;
    state.backgroundTitle = data.backgroundTitle;
    state.backgroundIcon = data.backgroundIcon;
  }
};
var actions = {
  getFormData: function getFormData(_ref, baseApi) {
    var commit = _ref.commit;
    (0, _data.initData)('api/sysPlatformConfig', {
      size: 1000
    }).then(function (res) {
      if (!res.content.length) {
        return;
      }
      var data = res.content[0];
      for (var item in data) {
        if (item.indexOf("Icon") !== -1) {
          data[item] = baseApi + 'workorderattach/' + data[item].split('/')[data[item].split('/').length - 1];
        }
      }
      commit('SET_UserMsg', data);
    });
  }
};
var _default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};
exports.default = _default;