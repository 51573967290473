"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: {
    showState: {
      type: Boolean,
      default: false
    },
    alarmContent: {
      type: String
    }
  },
  watch: {
    showState: {
      handler: function handler(val) {
        this.dialogVisible = val;
      }
    }
  },
  data: function data() {
    return {
      dialogVisible: false // 控制报警弹出框
    };
  },

  methods: {
    // 关闭弹出框事件
    closeDialog: function closeDialog() {
      this.dialogVisible = false;
      this.$emit('changeState');
    },
    // 跳转到报警详情页面
    jumpToAlarm: function jumpToAlarm() {
      console.log(1111);
    }
  }
};
exports.default = _default;